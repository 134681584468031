<template>
  <aRow>
    <aCol class="content-row" :span="24">
      <h3>
        <aRow type="flex" justify="space-between">
          <aCol> Link de Pagamento </aCol>
          <aCol v-if="redeResponse.tid != undefined" class="id">
            <span
              >ID transação Rede:
              <span class="cgreen">
                {{ redeResponse.tid }}
              </span>
            </span>
          </aCol>
        </aRow>
      </h3>

      <div class="list">
        <aRow class="infos" :gutter="20">
          <aCol :span="4">
            <span class="label">Valor:</span>
            {{
              formatPricePtBr(
                tempContract.payment_methods_payment_link_total_value
              )
            }}
          </aCol>
          <aCol :span="3">
            <span class="label">Parcelas:</span>
            {{ tempContract.payment_methods_payment_link_installments }}
          </aCol>

          <aCol :span="4">
            <span class="label">Primeira parcela:</span>
            {{
              formatPricePtBr(
                tempContract.payment_methods_payment_link_first_installment
              )
            }}
          </aCol>

          <aCol :span="4">
            <span class="label">Demais parcela:</span>
            {{
              formatPricePtBr(
                tempContract.payment_methods_payment_link_installments_value
              )
            }}
          </aCol>

          <aCol :span="3">
            <span class="label">Taxa PDQ:</span>
            {{
              formatPricePtBr(
                tempContract.payment_methods_payment_link_tax_value
              )
            }}
          </aCol>

          <aCol :span="3">
            <span class="label">Taxa Adm:</span>
            {{
              formatPricePtBr(
                tempContract.payment_methods_payment_link_advance_fee_value
              )
            }}
          </aCol>

          <aCol :span="3">
            <span class="label">Total Taxas:</span>
            {{
              formatPricePtBr(
                tempContract.payment_methods_payment_link_total_taxes
              )
            }}
          </aCol>
        </aRow>

        <aRow
          v-show="
            tempContract[`payment_methods_payment_link_total_value`] &&
            tempContract[`payment_methods_payment_link_installments`] &&
            tempContract[`payment_methods_payment_link_first_installment`]
          "
          class="link-generator mt-20"
          :gutter="20"
        >
          <aCol :span="24">
            <aRow :gutter="20">
              <aCol :span="24">
                <span class="label">Link para pagamento:</span>
                <a-form-item>
                  <a-input
                    class="pay-link"
                    id="copy-to-clipboard"
                    v-decorator="[
                      `payment_methods_payment_link_url`,
                      {
                        initialValue:
                          tempContract.payment_methods_payment_link_url,
                      },
                    ]"
                  >
                    <a-tooltip slot="suffix" title="Copiar o link">
                      <a-icon
                        type="copy"
                        style="color: rgba(0, 0, 0, 0.45)"
                        @click="copyToClipboard"
                      />
                    </a-tooltip>
                  </a-input>
                </a-form-item>
              </aCol>
            </aRow>
          </aCol>
        </aRow>

        <aRow class="gateway mt-20" :gutter="20">
          <aCol :span="24">
            <h4>Análise de risco</h4>
          </aCol>

          <aCol class="fields">
            <aRow :gutter="[20, 0]">
              <aCol :span="3">
                <a-form-item>
                  <div class="label">Score</div>
                  <a-input
                    class="travel-input"
                    placeholder=""
                    v-currency
                    v-decorator="[
                      `payment_methods_payment_link_risk_analysis_score`,
                    ]"
                    :disabled="true"
                  >
                  </a-input>
                </a-form-item>
              </aCol>

              <aCol :span="5">
                <a-form-item>
                  <div class="label">Status</div>
                  <a-input
                    class="travel-input"
                    placeholder=""
                    v-currency
                    v-decorator="[
                      `payment_methods_payment_link_risk_analysis_status`,
                    ]"
                    :disabled="true"
                  >
                  </a-input>
                </a-form-item>
              </aCol>

              <aCol :span="8">
                <a-form-item>
                  <label :class="'label'">Status Code</label>
                  <a-input
                    class="travel-input"
                    placeholder=""
                    v-currency
                    v-decorator="[
                      `payment_methods_payment_link_risk_analysis_status_code`,
                    ]"
                    :disabled="true"
                  >
                  </a-input>
                </a-form-item>
              </aCol>

              <aCol class="clearsale-actions" :span="8">
                <a-button
                  class="btn-1 mr-5"
                  type="primary"
                  :loading="sendEmailLoading"
                >
                  <img
                    src="../../../assets/images/dashboard/contracts/refresh-link.png"
                    alt="ico"
                  />
                </a-button>
                <a-button
                  class="btn-2 mr-5"
                  type="primary"
                  :loading="sendEmailLoading"
                >
                  <img
                    src="../../../assets/images/dashboard/contracts/transfer-link.png"
                    alt="ico"
                  />
                </a-button>
                <a-button
                  class="btn-3"
                  type="primary"
                  :loading="sendEmailLoading"
                >
                  <img
                    src="../../../assets/images/dashboard/contracts/send-link.png"
                    alt="ico"
                  />
                </a-button>
              </aCol>

              <aCol class="obs" :span="24">
                <b>Atenção:</b> Solicitar prioridade terá custo adicional na
                venda.
              </aCol>
            </aRow>
          </aCol>
        </aRow>

        <aRow
          v-if="
            redeResponse.tid != undefined &&
            redeTransactionStatus == 'Finalizado'
          "
          class="actions a-right"
          :gutter="20"
        >
          <aCol :span="24">
            <a-button
              class="button-green"
              type="primary"
              style="pointer-events: none"
            >
              FINALIZADO <a-icon type="like" />
            </a-button>
          </aCol>
        </aRow>
        <aRow
          v-if="
            redeResponse.tid != undefined &&
            redeTransactionStatus != 'Finalizado'
          "
          class="actions a-right"
          :gutter="20"
        >
          <aCol :span="24">
            <a-popconfirm
              title="Tem certeza que deseja cancelar a transação?"
              ok-text="Sim"
              placement="top"
              cancel-text="Não"
              @confirm="cancelRedeTransaction"
            >
              <a-button :loading="false" type="danger">
                Cancelar / Extornar
              </a-button>
            </a-popconfirm>

            <a-popconfirm
              title="Tem certeza que deseja capturar a transação?"
              ok-text="Sim"
              placement="top"
              cancel-text="Não"
              @confirm="captureRedeTransaction"
            >
              <a-button
                class="ml-10 button-green"
                :loading="false"
                type="primary"
              >
                Capturar
              </a-button>
            </a-popconfirm>
          </aCol>
        </aRow>
      </div>
    </aCol>
  </aRow>
</template>

<script>
import formatThings from "@/mixins/general/formatThings.js";

export default {
  name: "ContractPaymentMethodsPaymentLinkDetails",
  props: {
    tempContract: Object,
  },
  mixins: [formatThings],
  data() {
    return {
      redeResponse: {},
      redeTransactionStatus: undefined,
    };
  },

  mounted() {
    this.redeResponse =
      this.tempContract.payment_link_rede_response != undefined
        ? JSON.parse(this.tempContract.payment_link_rede_response)
        : {};

    this.redeTransactionStatus =
      this.tempContract.payment_link_rede_transaction_status != undefined
        ? this.tempContract.payment_link_rede_transaction_status
        : {};
  },
  methods: {
    captureRedeTransaction() {
      this.$http
        .post("/integrations/rede/capture", {
          value:
            this.tempContract.payment_methods_payment_link_total_value.replace(
              ",",
              "."
            ),
          //value: 10.0,
          tid: this.redeResponse.tid,
        })
        .then(({ data }) => {
          this.$message.success(data.message);
          // this.$emit("updateContractsList");

          this.$http.post("/log/create", {
            user_id: this.$store.state.userData.id,
            module_id: this.tempContract.id,
            module: "contract",
            action: "capture-rede-transaction",
            description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} finalizou a transação Rede ID ${this.redeResponse.tid} no contrato ${this.tempContract.id}.`,
          });

          this.redeTransactionStatus = "Finalizado";

          let updtContract = {};
          updtContract.id = this.tempContract.id;
          updtContract.payment_link_rede_transaction_status = "Finalizado";
          updtContract.modified_by = {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          };

          this.$http
            .post("/contract/update-multi-meta", updtContract)
            .then(({ data }) => {
              data;
              this.$message.success(
                `Dados do link de pagamento atualizados no contrato ${updtContract.id}!`
              );
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
            });
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    cancelRedeTransaction() {
      this.$http
        .post("/integrations/rede/cancel", {
          value:
            this.tempContract.payment_methods_payment_link_total_value.replace(
              ",",
              "."
            ),
          tid: this.redeResponse.tid,
        })
        .then(({ data }) => {
          this.$message.success(data.message);
          // this.$emit("updateContractsList");

          this.$http.post("/log/create", {
            user_id: this.$store.state.userData.id,
            module_id: this.tempContract.id,
            module: "contract",
            action: "cancel-rede-transaction",
            description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} cancelou a transação rede ID ${this.redeResponse.tid}  no contrato ${this.tempContract.id}.`,
          });

          let updtContract = {};

          updtContract.id = this.tempContract.id;
          updtContract.payment_link_rede_response = "";

          updtContract.modified_by = {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          };

          this.redeResponse = {};

          this.$http
            .post("/contract/update-multi-meta", updtContract)
            .then(({ data }) => {
              data;
              this.$message.success(
                `Dados do link de pagamento atualizados no contrato ${updtContract.id}!`
              );
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
            });
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    copyToClipboard() {
      let copyText = "";
      setTimeout(() => {
        copyText = document.getElementById("payment_methods_payment_link_url");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
        this.$message.info("Copiado!");
      }, 20);
    },
  },
};
</script>

<style lang="sass" scoped>
.button-green
  background: #2ecc71
  border-color: #2ecc71
  font-weight: 600
.button-green:hover, .button-green:active
  background: #2ecc71
  border-color: #2ecc71
  font-weight: 600
  opacity: 0.8

.content-row
  font-size: 14px
  font-weight: 600
  .actions
    border-top: 1px solid #ddd
    padding: 10px 0 0 0
    margin-top: 20px
  .label
    display: block
    font-size: 12px
    font-weight: 500
    line-height: 1.5
    color: #aaa
  h4
    padding: 0 0 6px 0
    font-size: 14px
    font-weight: 600
    margin-bottom: 15px
    color: #ed0e93
    text-transform: uppercase
    border-bottom: 1px solid #ececec
  h3
    padding: 0 0 6px 0
    font-size: 14px
    font-weight: 600
    margin-bottom: 15px
    color: #ed0e93
    text-transform: uppercase
    border-bottom: 1px solid #ececec
    .id
      font-weight: 500
      color: #00aa21
      font-size: 12px
      text-transform: none
      & font
        color: #333333
</style>

<style lang="sass">

.gateway
  .clearsale-actions
    padding: 23px 0 0 0
    button
      padding: 0 !important
      width: 32px
      border: 1px solid rgba(0,0,0,0.2)
    & .btn-1
      background: #6dd9bd !important
    & .btn-2
      background: #fd8b74 !important
    & .btn-3
      background: #e05c6e !important
  .obs
    font-size: 11px
    color: #333
    line-height: 1
    b
      color: #f00
      margin-bottom: 5px

.link-generator
  .pay-link
    input
      pointer-events: none
      font-size: 11px
      background: #f6f6f6 !important
</style>
