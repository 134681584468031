var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aRow',[_c('aCol',{staticClass:"content-row",attrs:{"span":24}},[_c('h3',[_c('aRow',{attrs:{"type":"flex","justify":"space-between"}},[_c('aCol',[_vm._v(" Link de Pagamento ")]),_vm._v(" "),(_vm.redeResponse.tid != undefined)?_c('aCol',{staticClass:"id"},[_c('span',[_vm._v("ID transação Rede:\n            "),_c('span',{staticClass:"cgreen"},[_vm._v("\n              "+_vm._s(_vm.redeResponse.tid)+"\n            ")])])]):_vm._e()],1)],1),_vm._v(" "),_c('div',{staticClass:"list"},[_c('aRow',{staticClass:"infos",attrs:{"gutter":20}},[_c('aCol',{attrs:{"span":4}},[_c('span',{staticClass:"label"},[_vm._v("Valor:")]),_vm._v("\n          "+_vm._s(_vm.formatPricePtBr(
              _vm.tempContract.payment_methods_payment_link_total_value
            ))+"\n        ")]),_vm._v(" "),_c('aCol',{attrs:{"span":3}},[_c('span',{staticClass:"label"},[_vm._v("Parcelas:")]),_vm._v("\n          "+_vm._s(_vm.tempContract.payment_methods_payment_link_installments)+"\n        ")]),_vm._v(" "),_c('aCol',{attrs:{"span":4}},[_c('span',{staticClass:"label"},[_vm._v("Primeira parcela:")]),_vm._v("\n          "+_vm._s(_vm.formatPricePtBr(
              _vm.tempContract.payment_methods_payment_link_first_installment
            ))+"\n        ")]),_vm._v(" "),_c('aCol',{attrs:{"span":4}},[_c('span',{staticClass:"label"},[_vm._v("Demais parcela:")]),_vm._v("\n          "+_vm._s(_vm.formatPricePtBr(
              _vm.tempContract.payment_methods_payment_link_installments_value
            ))+"\n        ")]),_vm._v(" "),_c('aCol',{attrs:{"span":3}},[_c('span',{staticClass:"label"},[_vm._v("Taxa PDQ:")]),_vm._v("\n          "+_vm._s(_vm.formatPricePtBr(
              _vm.tempContract.payment_methods_payment_link_tax_value
            ))+"\n        ")]),_vm._v(" "),_c('aCol',{attrs:{"span":3}},[_c('span',{staticClass:"label"},[_vm._v("Taxa Adm:")]),_vm._v("\n          "+_vm._s(_vm.formatPricePtBr(
              _vm.tempContract.payment_methods_payment_link_advance_fee_value
            ))+"\n        ")]),_vm._v(" "),_c('aCol',{attrs:{"span":3}},[_c('span',{staticClass:"label"},[_vm._v("Total Taxas:")]),_vm._v("\n          "+_vm._s(_vm.formatPricePtBr(
              _vm.tempContract.payment_methods_payment_link_total_taxes
            ))+"\n        ")])],1),_vm._v(" "),_c('aRow',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.tempContract[`payment_methods_payment_link_total_value`] &&
          _vm.tempContract[`payment_methods_payment_link_installments`] &&
          _vm.tempContract[`payment_methods_payment_link_first_installment`]
        ),expression:"\n          tempContract[`payment_methods_payment_link_total_value`] &&\n          tempContract[`payment_methods_payment_link_installments`] &&\n          tempContract[`payment_methods_payment_link_first_installment`]\n        "}],staticClass:"link-generator mt-20",attrs:{"gutter":20}},[_c('aCol',{attrs:{"span":24}},[_c('aRow',{attrs:{"gutter":20}},[_c('aCol',{attrs:{"span":24}},[_c('span',{staticClass:"label"},[_vm._v("Link para pagamento:")]),_vm._v(" "),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    `payment_methods_payment_link_url`,
                    {
                      initialValue:
                        _vm.tempContract.payment_methods_payment_link_url,
                    },
                  ]),expression:"[\n                    `payment_methods_payment_link_url`,\n                    {\n                      initialValue:\n                        tempContract.payment_methods_payment_link_url,\n                    },\n                  ]"}],staticClass:"pay-link",attrs:{"id":"copy-to-clipboard"}},[_c('a-tooltip',{attrs:{"slot":"suffix","title":"Copiar o link"},slot:"suffix"},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.45)"},attrs:{"type":"copy"},on:{"click":_vm.copyToClipboard}})],1)],1)],1)],1)],1)],1)],1),_vm._v(" "),_c('aRow',{staticClass:"gateway mt-20",attrs:{"gutter":20}},[_c('aCol',{attrs:{"span":24}},[_c('h4',[_vm._v("Análise de risco")])]),_vm._v(" "),_c('aCol',{staticClass:"fields"},[_c('aRow',{attrs:{"gutter":[20, 0]}},[_c('aCol',{attrs:{"span":3}},[_c('a-form-item',[_c('div',{staticClass:"label"},[_vm._v("Score")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
                    `payment_methods_payment_link_risk_analysis_score`,
                  ]),expression:"[\n                    `payment_methods_payment_link_risk_analysis_score`,\n                  ]"}],staticClass:"travel-input",attrs:{"placeholder":"","disabled":true}})],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":5}},[_c('a-form-item',[_c('div',{staticClass:"label"},[_vm._v("Status")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
                    `payment_methods_payment_link_risk_analysis_status`,
                  ]),expression:"[\n                    `payment_methods_payment_link_risk_analysis_status`,\n                  ]"}],staticClass:"travel-input",attrs:{"placeholder":"","disabled":true}})],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":8}},[_c('a-form-item',[_c('label',{class:'label'},[_vm._v("Status Code")]),_vm._v(" "),_c('a-input',{directives:[{name:"currency",rawName:"v-currency"},{name:"decorator",rawName:"v-decorator",value:([
                    `payment_methods_payment_link_risk_analysis_status_code`,
                  ]),expression:"[\n                    `payment_methods_payment_link_risk_analysis_status_code`,\n                  ]"}],staticClass:"travel-input",attrs:{"placeholder":"","disabled":true}})],1)],1),_vm._v(" "),_c('aCol',{staticClass:"clearsale-actions",attrs:{"span":8}},[_c('a-button',{staticClass:"btn-1 mr-5",attrs:{"type":"primary","loading":_vm.sendEmailLoading}},[_c('img',{attrs:{"src":require("../../../assets/images/dashboard/contracts/refresh-link.png"),"alt":"ico"}})]),_vm._v(" "),_c('a-button',{staticClass:"btn-2 mr-5",attrs:{"type":"primary","loading":_vm.sendEmailLoading}},[_c('img',{attrs:{"src":require("../../../assets/images/dashboard/contracts/transfer-link.png"),"alt":"ico"}})]),_vm._v(" "),_c('a-button',{staticClass:"btn-3",attrs:{"type":"primary","loading":_vm.sendEmailLoading}},[_c('img',{attrs:{"src":require("../../../assets/images/dashboard/contracts/send-link.png"),"alt":"ico"}})])],1),_vm._v(" "),_c('aCol',{staticClass:"obs",attrs:{"span":24}},[_c('b',[_vm._v("Atenção:")]),_vm._v(" Solicitar prioridade terá custo adicional na\n              venda.\n            ")])],1)],1)],1),_vm._v(" "),(
          _vm.redeResponse.tid != undefined &&
          _vm.redeTransactionStatus == 'Finalizado'
        )?_c('aRow',{staticClass:"actions a-right",attrs:{"gutter":20}},[_c('aCol',{attrs:{"span":24}},[_c('a-button',{staticClass:"button-green",staticStyle:{"pointer-events":"none"},attrs:{"type":"primary"}},[_vm._v("\n            FINALIZADO "),_c('a-icon',{attrs:{"type":"like"}})],1)],1)],1):_vm._e(),_vm._v(" "),(
          _vm.redeResponse.tid != undefined &&
          _vm.redeTransactionStatus != 'Finalizado'
        )?_c('aRow',{staticClass:"actions a-right",attrs:{"gutter":20}},[_c('aCol',{attrs:{"span":24}},[_c('a-popconfirm',{attrs:{"title":"Tem certeza que deseja cancelar a transação?","ok-text":"Sim","placement":"top","cancel-text":"Não"},on:{"confirm":_vm.cancelRedeTransaction}},[_c('a-button',{attrs:{"loading":false,"type":"danger"}},[_vm._v("\n              Cancelar / Extornar\n            ")])],1),_vm._v(" "),_c('a-popconfirm',{attrs:{"title":"Tem certeza que deseja capturar a transação?","ok-text":"Sim","placement":"top","cancel-text":"Não"},on:{"confirm":_vm.captureRedeTransaction}},[_c('a-button',{staticClass:"ml-10 button-green",attrs:{"loading":false,"type":"primary"}},[_vm._v("\n              Capturar\n            ")])],1)],1)],1):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }